import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';

import { FeaturesComponent } from './features.component';

import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { AccountsComponent } from './accounts/components/accounts/accounts.component';
import { OperatorsComponent } from './operators/components/operators/operators.component';

import { OperationsReportsComponent } from './operations/components/operations-reports/operations-reports.component';
import { OperationsPhotographiesComponent } from './operations/components/operations-photographies/operations-photographies.component';
import { FirmwaresComponent } from './firmwares/components/firmwares/firmwares.component';
import { DashboardResolver } from '../shared/resolvers/dashboard/dashboard.resolver';
import { StoreOrdersComponent } from './store/components/store-orders/store-orders.component';
import { DevicesComponent } from './devices/components/devices/devices.component';
import { DevicesCreateComponent } from './devices/components/devices-create/devices-create.component';
import { RouteReuseStrategyReload } from '../app-route-reuse-strategy';
import { FeedbacksComponent } from './feedbacks/components/feedbacks/feedbacks.component';

const routes: Routes = [
    {
        path: '',
        component: FeaturesComponent, // Routes for the router outlet of the features component
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardComponent, resolve: { dashboard: DashboardResolver } },
            { path: 'accounts', component: AccountsComponent },
            { path: 'operators', component: OperatorsComponent },

            { path: 'operations/reports', component: OperationsReportsComponent },
            { path: 'operations/photographies', component: OperationsPhotographiesComponent },

            { path: 'store/orders', component: StoreOrdersComponent },

            { path: 'firmwares', component: FirmwaresComponent },
            { path: 'devices', component: DevicesComponent },
            { path: 'devices/create', component: DevicesCreateComponent },

            { path: 'feedbacks', component: FeedbacksComponent },
        ]
    }
];




@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: RouteReuseStrategyReload
        }
    ]
})
export class FeaturesRoutingModule { }

