<div class="layout vertical scrollable" (wuiScrolledBottom)="load(true)" [wuiScrolledBottomThreshold]="100">

    <table>

        <tr class="header">

            <!-- <td>
                <input type="checkbox">
            </td> -->

            <td>n. </td>

            <td>
                id
                <fa-icon
                    [class.active]="filters['id'] !== undefined"
                    (click)="searchBy('id')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                version
                <fa-icon
                    [class.active]="filters['version'] !== undefined"
                    (click)="searchBy('version')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                mode
                <fa-icon
                    [class.active]="filters['mode'] !== undefined"
                    (click)="searchBy('mode')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                begin
                <fa-icon
                    [class.active]="filters['begin'] !== undefined"
                    (click)="searchBy('begin')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                end
                <fa-icon
                    [class.active]="filters['end'] !== undefined"
                    (click)="searchBy('end')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                operators count
            </td>

            <td>
                actions
            </td>
        </tr>

        @for(line of lines; track line; let index = $index) {

        <tr (click)="edit(line.data.id)">

                <!-- <td>
                    <input type="checkbox" [checked]="line.isSelected">
                </td> -->

                <td>{{ index + 1 }}</td>

                <td>{{ line.data.id }}</td>

                <td>{{ line.data.version }}</td>

                <td>{{ line.data.mode }}</td>

                <td>{{ line.data.begin | date : 'yyyy.MM.dd  HH:mm' }}</td>

                <td>{{ line.data.end | date : 'yyyy.MM.dd  HH:mm' }}</td>

                <td>{{ line.data.operators.length }}</td>

                <td wuiStopPropagation>
                    <fa-icon (click)="view(line.data.id)" [icon]="icons.view"></fa-icon>
                    <fa-icon (click)="delete(line.data.id)" [icon]="icons.delete"></fa-icon>
                </td>

            </tr>
        }

    </table>

</div>
