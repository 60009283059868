import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { faMagnifyingGlass, faWindowMaximize, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalConfirmComponent } from '@lightning/backoffice/app/shared/components/modal-confirm/modal-confirm.component';
import { ModalTableFilterComponent } from '@lightning/backoffice/app/shared/components/modal-table-filter/modal-table-filter.component';
import { FiltersOptions } from '@lightning/backoffice/app/shared/models/table-filters-options.types';
import { TableLine } from '@lightning/backoffice/app/shared/models/table-line.types';
import { OnlineService } from '@lightning/lightning-services';
import { KeyValuePipe, OverlayService } from '@lightning/wild-ui';

import { FeedbacksEditComponent } from '../feedbacks-edit/feedbacks-edit.component';
import { Account, Feedback, FeedbacksSearchRequest, FeedbacksSearchResponse } from '@lightning/lightning-definitions';
import { AccountsEditComponent } from '../../../accounts/components/accounts-edit/accounts-edit.component';

@Component({
    selector: 'app-feedbacks',
    templateUrl: './feedbacks.component.html',
    styleUrls: ['./feedbacks.component.scss']
})
export class FeedbacksComponent implements OnInit {

    public icons = {
        search: faMagnifyingGlass,
        view: faWindowMaximize,
        delete: faXmarkCircle
    };

    public lines: Array<TableLine<Feedback>> = [];

    public filters: { [filter: string]: string } = {};

    private isLoading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private keyValuePipe: KeyValuePipe) { }

    ngOnInit(): void {

        this.filters = structuredClone(this.route.snapshot.queryParams);

        this.load(false);
    }

    public load(next: boolean): void {

        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        const request: FeedbacksSearchRequest = {
            index: next ? this.lines.length : 0,
            limit: 50,
            ...this.filters
        }

        this.onlineService
            .searchFeedbacks(request)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: FeedbacksSearchResponse) => {

                    const lines: Array<TableLine<Feedback>> = response.items.map((feedback) => {

                        return {
                            isSelected: false,
                            data: feedback,
                            calculated: {
                                // createdColor:
                                //     ColorUtils.textToColor(new Date(deviceRegistration.created.toString().split('T')[0]).toString(), { brightness: 'light' }),
                                // productColor:
                                //     ColorUtils.textToColor(deviceRegistration.product, { brightness: 'light' }),
                            }
                        }
                    });

                    this.lines = next ? this.lines.concat(lines) : lines;
                }
            });
    }

    public async searchBy(filter: string) {

        const options: FiltersOptions = {
            description: 'search by ' + filter,
            type: 'text',
            choices: [],
            value: this.filters[filter]
        };

        switch(filter) {

            case 'number':
                options.type = 'number';
            break;

            // case 'status':
            //     options.choices = this.keyValuePipe.transform(FeedbackStatus);
            // break;

        }

        // Selection modal
        const value: string | undefined = await this.overlayService.openModal({
            component: ModalTableFilterComponent,
            inputs: {
                options
            }
        });

        // Canceled
        if(value === undefined) {
            return;
        }

        if(value === '') {
            delete this.filters[filter];
        } else {
            this.filters[filter] = value;
        }

        this.load(false);

        // Update the url according the filter
        this.router.navigate([], { queryParams: this.filters });
    }

    public async edit(feedback: Feedback) {

        const updatedFeedback = await this.overlayService.openModal({
            component: FeedbacksEditComponent,
            inputs: {
                title: `Feedback ${feedback.id}`,
                feedback
            }
        });

        if (updatedFeedback) {
            this.load(false);
        }
    }

    public editAccount(id: string | undefined): void {

        if (id === undefined) {
            return;
        }

        this.onlineService.getAccount(id).subscribe({
            next: (account: Account) => {
                this.overlayService.openModal({
                    component: AccountsEditComponent,
                    inputs: {
                        title: `Account ${account.id}`,
                        account
                    }
                });
            }
        });
    }

    public async delete(id: string) {

        if (await this.overlayService.openModal({
            component: ModalConfirmComponent,
            inputs: {
                title: `Feedback delete`,
                description: `Are you sure you want to remove the feedback ${id}?`
            }})) {

            this.onlineService.deleteFeedback(id).subscribe({
                next: () => {
                    this.lines = this.lines.filter(line => line.data.id !== id);
                }
            })
        }

    }

}
