<wui-modal-layout>

    <div title>{{ title }}</div>

    <div body>

        <wui-section name="description">
            <wui-entry>
                <div name>Id</div>
                <div value>
                    {{ feedback.id }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Created</div>
                <div value>
                    {{ feedback.created }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Author</div>
                <wui-button value (click)="editAccount(feedback.authorAccountId)">
                    view account
                </wui-button>
            </wui-entry>
            <wui-entry>
                <div name>Description</div>
                <div value>
                    {{ feedback.description }}
                </div>
            </wui-entry>

        </wui-section>

        @if(feedback.screenshot) {
            <wui-section name="screenshot">
                <img [src]="feedback.screenshot">
            </wui-section>
        }

        @if(feedback.context) {
            <wui-section name="context">
                <wui-json [value]="feedback.context"></wui-json>
            </wui-section>
        }

        <!-- <wui-section name="raw">
            <wui-json [value]="feedback"></wui-json>
        </wui-section> -->

    </div>

    <wui-buttons footer>
        <wui-button (click)="cancel()">cancel</wui-button>
        <!-- <wui-button (click)="confirm()" [wuiDisabled]="isSending">confirm</wui-button> -->
    </wui-buttons>

</wui-modal-layout>
