<div class="layout vertical scrollable" (wuiScrolledBottom)="load(true)" [wuiScrolledBottomThreshold]="100">

    <table>

        <tr class="header">

            <td>n. </td>

            <td>
                id
                <fa-icon
                    [class.active]="filters['id'] !== undefined"
                    (click)="searchBy('id')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                number
                <fa-icon
                    [class.active]="filters['number'] !== undefined"
                    (click)="searchBy('number')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                name
                <fa-icon
                    [class.active]="filters['name'] !== undefined"
                    (click)="searchBy('name')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                status
                <fa-icon
                    [class.active]="filters['status'] !== undefined"
                    (click)="searchBy('status')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                is legend
                <fa-icon
                    [class.active]="filters['isLegend'] !== undefined"
                    (click)="searchBy('isLegend')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                nfc tags
            </td>

            <td>
                actions
            </td>
        </tr>

        @for(line of lines; track line; let index = $index) {

            <tr (click)="edit(line.data)">

                <td>{{ index + 1 }}</td>

                <td>{{ line.data.id }}</td>

                <td>{{ line.data.number }}</td>

                <td>{{ line.data.name }}</td>

                <td [style.color]="'var(--color-operators-status-' + line.data.status + ')'">{{ line.data.status }}</td>

                <td>{{ line.data.isLegend ? 'legend' : ''}}</td>

                <td>{{ line.data.nfcTags.length }} {{ line.calculated.hasUntrackedtags ? ' (has untracked)' : '' }} </td>

                <td wuiStopPropagation>
                    <fa-icon (click)="view(line.data.id)" [icon]="icons.view"></fa-icon>
                    <fa-icon (click)="delete(line.data.id)" [icon]="icons.delete"></fa-icon>
                </td>

            </tr>
        }

    </table>

</div>
