import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';

import { KeyValuePipe, OverlayService, StringUtils } from '@lightning/wild-ui';

import { faMagnifyingGlass, faWindowMaximize, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { DeviceRegistration, DevicesRegistrationsSearchResponse, Products } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';

import { ModalConfirmComponent } from '@lightning/backoffice/app/shared/components/modal-confirm/modal-confirm.component';
import { ModalTableFilterComponent } from '@lightning/backoffice/app/shared/components/modal-table-filter/modal-table-filter.component';
import { FiltersOptions } from '@lightning/backoffice/app/shared/models/table-filters-options.types';
import { TableLine } from '@lightning/backoffice/app/shared/models/table-line.types';
import { LaserService } from '@lightning/backoffice/app/shared/services/laser/laser.service';
import { ColorUtils } from '@lightning/utils';

@Component({
    selector: 'app-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {

    public icons = {
        search: faMagnifyingGlass,
        view: faWindowMaximize,
        delete: faXmarkCircle
    };

    public lines: Array<TableLine<DeviceRegistration>> = [];

    public filters: { [filter: string]: string } = {};

    private isLoading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private keyValuePipe: KeyValuePipe,
        private laserService: LaserService) { }

    ngOnInit(): void {

        this.filters = structuredClone(this.route.snapshot.queryParams);

        this.load(false);
    }

    public load(next: boolean): void {

        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        const request = {
            ...this.filters,
            index: next ? this.lines.length : 0,
            limit: 50
        };

        this.onlineService
            .searchDevicesRegistrations(request)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: DevicesRegistrationsSearchResponse) => {

                    const lines: Array<TableLine<DeviceRegistration>> = response.items.map((deviceRegistration) => {

                        return {
                            isSelected: false,
                            data: deviceRegistration,
                            calculated: {
                                createdColor:
                                    ColorUtils.textToColor(new Date(deviceRegistration.created.toString().split('T')[0]).toString(), { brightness: 'light' }),
                                productColor:
                                    ColorUtils.textToColor(deviceRegistration.product, { brightness: 'light' }),
                            }
                        }
                    });

                    this.lines = next ? this.lines.concat(lines) : lines;
                }
            });
    }

    public async searchBy(filter: string) {

        const options: FiltersOptions = {
            description: 'search by ' + filter,
            type: 'text',
            choices: [],
            value: this.filters[filter]
        };

        switch(filter) {

            case 'created':
                options.value = new Date().toISOString().slice(0, 10);
            break;

            case 'product':
                options.choices = this.keyValuePipe.transform(Products);
            break;
        }

        // Selection modal
        const value: string | undefined = await this.overlayService.openModal({
            component: ModalTableFilterComponent,
            inputs: {
                options
            }
        });

        // Canceled
        if(value === undefined) {
            return;
        }

        if(value === '') {
            delete this.filters[filter];
        } else {
            this.filters[filter] = value;
        }

        this.load(false);

        // Update the url according the filter
        this.router.navigate([], { queryParams: this.filters });
    }

    public async delete(id: string) {

        if (await this.overlayService.openModal({
            component: ModalConfirmComponent,
            inputs: {
                title: `Device registration delete`,
                description: `Are you sure you want to remove the registration of the device ${id}?`
            }})) {

            this.onlineService.deleteDeviceRegistration(id).subscribe({
                next: () => {
                    this.lines = this.lines.filter(line => line.data.id !== id);
                }
            })
        }

    }

    public mark(device: DeviceRegistration): void {

        const { product, version, serial } = device;

        let details = {};

        let templateName = StringUtils.toKebabCase(product);

        switch(device.product) {

            case Products.NfcTagOperator:
                details = {
                    number: device.details.operatorNumber,
                    name:   device.details.operatorName,
                };
            break;

            case Products.NfcTagSystem:

                templateName += `-${ StringUtils.toKebabCase(device.details.action)}`;

            break;


        }

        this.laserService.getLaserMarkingProject(templateName, { version, serial, ...details });
    }

}
