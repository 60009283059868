<div class="layout vertical scrollable" (wuiScrolledBottom)="load(true)" [wuiScrolledBottomThreshold]="100">

    <table>

        <tr class="header">

            <!-- <td>
                <input type="checkbox" (change)="selectAll($event)">
            </td> -->

            <td>n. </td>

            <td>
                id
                <fa-icon
                    [class.active]="filters['id'] !== undefined"
                    (click)="searchBy('id')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                email
                <fa-icon
                    [class.active]="filters['email'] !== undefined"
                    (click)="searchBy('email')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                name
                <fa-icon
                    [class.active]="filters['name'] !== undefined"
                    (click)="searchBy('name')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                roles
                <fa-icon
                    [class.active]="filters['roles'] !== undefined"
                    (click)="searchBy('roles')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                status
                <fa-icon
                    [class.active]="filters['status'] !== undefined"
                    (click)="searchBy('status')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                operator profile
            </td>

            <td>
                actions
            </td>
        </tr>

        @for(line of lines; track line; let index = $index) {

            <tr (click)="edit(line.data)">

                <!-- <td>
                    <input type="checkbox" [checked]="line.isSelected" (click)="select(line, $event)">
                </td> -->

                <td>{{ index + 1 }}</td>

                <td>{{ line.data.id }}</td>

                <td>{{ line.data.email }}</td>

                <td>{{ line.data.name }}</td>

                <td>{{ line.data.roles }}</td>

                <td [style.color]="'var(--color-accounts-status-' + line.data.status + ')'">{{ line.data.status }}</td> <!-- account status css to define -->

                <td (click)="editOperatorProfile(line.data.operatorId); $event.stopPropagation()">{{ line.data.operatorId }}</td>

                <td wuiStopPropagation>
                    <fa-icon (click)="delete(line.data.id)" [icon]="icons.delete"></fa-icon>
                </td>

            </tr>
        }

    </table>

</div>
