import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { FeaturesRoutingModule } from './features-routing.module';

import { FeaturesComponent } from './features.component';
import { AccountsModule } from './accounts/accounts.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { OperatorsModule } from './operators/operators.module';
import { OperationsModule } from './operations/operations.module';
import { StoreModule } from './store/store.module';
import { FirmwaresModule } from './firmwares/firmwares.module';
import { SharedModule } from '../shared/shared.module';
import { DevicesModule } from './devices/devices.module';
import { FeedbacksModule } from './feedbacks/feedbacks.module';

@NgModule({
    declarations: [
        FeaturesComponent,
    ],
    imports: [
        CommonModule,
        FeaturesRoutingModule,
        SharedModule,
        DashboardModule,
        AccountsModule,
        OperatorsModule,
        OperationsModule,
        StoreModule,
        FirmwaresModule,
        DevicesModule,
        FeedbacksModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class FeaturesModule { }
