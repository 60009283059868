<wui-modal-layout>

    <div title>{{ title }}</div>

    <div body>

        <wui-section name="description">
            <wui-entry>
                <div name>Id</div>
                <div value>
                    {{ order.id }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Date</div>
                <div value>
                    {{ order.date | date: 'YYYY-MM-dd HH:mm' }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Amount</div>
                <div value>{{ order.amount | currency: 'EUR'}}</div>
            </wui-entry>
            <wui-entry>
                <div name>Status</div>
                <wui-select value [value]="order.status" #status
                    (valueChange)="updateStatus(status.value)"
                    [required]="true"
                    [style.color]="'var(--color-store-orders-status-' + (order.status | uncapitalize ) + ')'">

                    @for(status of Status | keyValue; track status) {
                        <option
                            [value]="status.value"
                            [style.color]="'var(--color-store-orders-status-' + (status.value | uncapitalize ) + ')'">
                                {{ status.key }}
                        </option>
                    }
                </wui-select>
            </wui-entry>
        </wui-section>

        <wui-section name="items">

            @for(item of order.items; track item) {
                <wui-entry>
                    <div name>{{ item.name }} x {{ item.quantity }}</div>

                    <div value>
                        <wui-json [value]="item"></wui-json>

                        <!-- Shortcut to create the operator tag -->
                        @if(item.name === Products.NfcTagOperator) {
                            <wui-button (click)="createNfcTagOperator(item)">
                                create
                            </wui-button>
                        }
                    </div>
                </wui-entry>
            }

        </wui-section>

        <wui-section name="address">
            <wui-entry>
                <div name>{{ 'shared.firstName' | translate | capitalize }}</div>
                <div value>{{ order.address.firstName }}</div>
            </wui-entry>
            <wui-entry>
                <div name>{{ 'shared.lastName' | translate | capitalize }}</div>
                <div value>{{ order.address.lastName }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.street' | translate | capitalize }}</div>
                <div value>{{ order.address.street }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.postalCode' | translate | capitalize }}</div>
                <div value>{{ order.address.postalCode }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.city' | translate | capitalize }}</div>
                <div value>{{ order.address.city }}</div>
            </wui-entry>

            <wui-entry>
                <div name>{{ 'shared.country' | translate | capitalize }}</div>
                <div value>{{ order.address.country }}</div>
            </wui-entry>

        </wui-section>

        <wui-section name="raw">
            <wui-json [value]="order"></wui-json>
        </wui-section>

    </div>



    <wui-buttons footer>
        <wui-button (click)="cancel()">close</wui-button>
        <!-- <wui-button (click)="confirm()" [wuiDisabled]="isSending">confirm</wui-button> -->
    </wui-buttons>

</wui-modal-layout>
