import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbacksComponent } from './components/feedbacks/feedbacks.component';
import { SharedModule } from '../../shared/shared.module';
import { FeedbacksEditComponent } from './components/feedbacks-edit/feedbacks-edit.component';

@NgModule({
    declarations: [
        FeedbacksComponent,
        FeedbacksEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class FeedbacksModule {}
