@if(environment.production) {

    <div class="body" (wuiScrolledBottom)="load(true)" [wuiScrolledBottomThreshold]="100">

        <table>

            <tr class="tableHeader">

                <td>n. </td>

                <td>
                    released
                </td>

                <td>
                    target
                </td>

                <td>
                    firmware
                    <fa-icon
                        [class.active]="filters['version'] !== undefined"
                        (click)="searchBy('version')"
                        [icon]="icons.search"></fa-icon>
                </td>

                <!-- <td>
                    code name
                    <fa-icon
                        [class.active]="filters['name'] !== undefined"
                        (click)="searchBy('name')"
                        [icon]="icons.search"></fa-icon>
                </td> -->

                <td>
                    channel
                    <fa-icon
                        [class.active]="filters['channel'] !== undefined"
                        (click)="searchBy('channel')"
                        [icon]="icons.search"></fa-icon>
                </td>

                <td>
                    actions
                </td>
            </tr>

            @for (line of lines; track line; let i = $index) {

                <tr (click)="edit(line.data)" [title]="line.data.description">

                    <td>{{ i + 1 }}</td>

                    <td [style.color]="line.calculated.createdColor">
                        {{ line.data.release | date: 'yyyy.MM.dd HH:mm'}}
                    </td>

                    <td [style.color]="line.calculated.targetColor">
                        {{ line.data.target.product }} {{ line.data.target.hardwareVersion.major }}.{{ line.data.target.hardwareVersion.minor }}.X
                    </td>

                    <td [style.color]="line.calculated.firmwareColor">
                        {{ line.data.version.major }}.{{ line.data.version.minor }}.{{ line.data.version.patch }}
                    </td>

                    <!-- <td>{{ line.data.name }}</td> -->

                    <td>{{ line.data.channel }}</td>

                    <td wuiStopPropagation>
                        <fa-icon (click)="delete(line.data.id)" [icon]="icons.delete"></fa-icon>
                    </td>

                </tr>
            }

        </table>

        <br/>

    </div>

    <wui-buttons class="footer">
        <wui-button (click)="create()">create</wui-button>
    </wui-buttons>

} @else {

    <wui-placeholder>
        Firmwares are managed and stored by the production environment only.
    </wui-placeholder>

}
