
export enum NfcTagsTypes {
    Operator =  'OPR',
    Guest =     'GST',
    Resource =  'RES',
    Part =      'PRT', // Special & part -> Item ?
    Special =   'SPL',

    Location =  'LCT',
    System =    'SYS'
}

export enum NfcTagsSpecialsActions {
    OperationStartResume =  'operationStartResume',
    OperationPause =        'operationPause',
    OperationComplete =     'operationComplete',

    // TODO: Create 'Items' tags
    Explosive =             'explosive',
    Drill =                 'drill'
}

export enum NfcTagsSystemActions {
    Update =        'update',
    RangeTest =     'rangeTest',
    FactoryReset =  'factoryReset'
}
