import { Expose, Transform } from 'class-transformer';
import { ApiProperty, Default, IsEnum, IsMongoId, IsNumber, IsOptional, IsString, Max, Min } from '@lightning/decorators';
import { DeviceRegistration } from '../entities/device.entity';
import { PaginedRequest, PaginedResponse } from './pagined.dto';
import { GroundLocations } from '../enums/ground-locations.enum';
import { NfcTagsSpecialsActions, NfcTagsSystemActions } from '../enums/nfc-tags.enums';

export class DevicesRegistrationsSearchRequest extends PaginedRequest {

    @ApiProperty()
    @Expose()
    @IsOptional()
    @IsMongoId()
    public id?: string;

    @ApiProperty()
    @Expose()
    @IsOptional()
    public created?: string;

    @ApiProperty()
    @Expose()
    @IsOptional()
    public serial?: string;

    @ApiProperty()
    @Expose()
    @IsOptional()
    public product?: string;

    @ApiProperty()
    @Expose()
    @IsOptional()
    public model?: string;

    @ApiProperty()
    @Expose()
    @IsOptional()
    public orderId?: string;

    @ApiProperty()
    @Expose()
    @IsOptional()
    @Default(-1)    // Latest first by default
    @Transform(({ value }) => Number(value))
    @IsNumber()
    @Min(-1)
    @Max(1)
    public sortByDate?: number;
}

export class DevicesRegistrationsSearchResponse extends PaginedResponse {

    @ApiProperty()
    public items!: Array<DeviceRegistration>;
}

class DeviceCreateRequest {

    @ApiProperty()
    @Expose()
    @IsString()
    public serial = '';

    @ApiProperty()
    @Expose()
    @IsString()
    public version = '';   // hardwareVersion
}

export class DeviceCreateNfcTagOperatorRequest extends DeviceCreateRequest {

    @ApiProperty()
    @Expose()
    @IsNumber()
    public lifeTime = 1;

    @ApiProperty()
    @Expose()
    @IsString()
    public accountId = '';

    @ApiProperty()
    @Expose()
    @IsString()
    public operatorName = '';
}

export class DeviceCreateNfcTagGuestRequest extends DeviceCreateRequest{

    @ApiProperty()
    @Expose()
    @IsNumber()
    public number = 1;
}

export class DeviceCreateNfcTagResourceRequest extends DeviceCreateRequest {

    @ApiProperty()
    @Expose()
    @IsNumber()
    public value = 1000;
}

export class DeviceCreateNfcTagPartRequest extends DeviceCreateRequest {

    @ApiProperty()
    @Expose()
    @IsNumber()
    public number = 1;
}

export class DeviceCreateNfcTagLocationRequest extends DeviceCreateRequest {

    @ApiProperty()
    @Expose()
    @IsEnum(GroundLocations)
    public location = '';
}

export class DeviceCreateNfcTagSpecialRequest extends DeviceCreateRequest {

    @ApiProperty()
    @Expose()
    @IsEnum(NfcTagsSpecialsActions)
    public action = '';
}

export class DeviceCreateNfcTagSystemRequest extends DeviceCreateRequest {

    @ApiProperty()
    @Expose()
    @IsEnum(NfcTagsSystemActions)
    public action = '';
}






export class DeviceCreateHeadquarterGatewayRequest extends DeviceCreateRequest {

}

export class DeviceCreateGroundModuleRequest extends DeviceCreateRequest {

}

