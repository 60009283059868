import { Injectable } from '@angular/core';
import { FilesUtils } from '@lightning/utils';
import { isNumber, isString } from 'class-validator';

@Injectable({
  providedIn: 'root'
})
export class LaserService {

    public async getLaserMarkingProject(templateName: string, values: any): Promise<void> {

        const templatePath = `/assets/templates/xtool/${templateName}.xcs`;

        const fileNameFields = new Array<string>();

        let content = (await (await fetch(templatePath)).text());

        for (const key in values) {
            if (Object.prototype.hasOwnProperty.call(values, key)) {

                const tag = `\\.${key}\\.`;

                let value = values[key];

                if (Number.isInteger(value)) {
                    value = String(value).padStart(3, '0');
                }

                if (!Number.isNaN(Date.parse(value))) {
                    value = String(value).split('T')[0];
                }

                if (isString(value) || isNumber(value)) {
                    fileNameFields.push(value.toString());
                }

                content = content.replace(new RegExp(tag, 'gi'), value);

                console.log(`Tag for key ${key} updated by value ${value}`);
            }
        }

        FilesUtils.save(content, `${templateName}-${fileNameFields.join('-')}.xcs`);
    }

}
