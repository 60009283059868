<div class="card" wuiTacticalCorners>
    <div class="title">{{title}}</div>
    <div class="logo" [wuiBackgroundImage]="logo"></div>
    <div class="description">{{description}}</div>

    @if(badge) {
        <div class="badge" [title]="badge">{{ badge.charAt(0).toUpperCase() }}</div>
    }

</div>
