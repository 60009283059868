/* eslint-disable @angular-eslint/no-input-rename */
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, HostBinding } from '@angular/core';
import { CapitalizePipe } from '../../pipes/capitalize/capitalize.pipe';
import { StringUtils } from '../../utils/string.utils';

// export type InputType = 'text' | 'email' | 'number' | 'password' | 'checkbox' | 'color';

export type InputFormat = 'lowercase' | 'uppercase' | 'capitalized' | '';

@Component({
    selector: 'wui-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

    public realValue: any;

    public isInvalid = false;

    @Input()
    public autofocus = false;

    @Input()
    public required = false;

    public type = 'text';

    @Input('type')
    public set typeProxy(type: string) {

        if (type === 'email') {
            this.pattern = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
        }

        this.type = type;
    }

    @Input()
    public min?: number;

    @Input()
    public max?: number;

    @Input()
    public pattern = '';

    @Input()
    public placeholder = '';

    @Input()
    public autocomplete: string | undefined = undefined; // Empty string is depreciated for autocomplete

    @Input()
    public suggestions: Array<string> = [];

    public suggestionsId = `suggestions-${new Date().getTime()}${Math.round(Math.random() * 100000).toString()}`;  // The list system works with ids, generate an unique one to prevent conflicts with others input components.

    @Input()
    public format: InputFormat = '';

    @Input()
    public set value(value: any) {

        if(value === null) {
            return;
        }

        if (this.type === 'number') {
            value = parseInt(value, 10);
        }

        this.realValue = value;

        this.valueChange.emit(this.realValue);

        this.checkValidity();

    }

    @Output()
    public valueChange = new EventEmitter<any>();

    @HostBinding('class.checkbox')
    private isCheckbox = false;

    constructor(private elementRef: ElementRef) { }

    ngOnInit(): void {

        if (this.autofocus) {
            this.elementRef.nativeElement.querySelector('input').focus();
        }

        this.isCheckbox = this.type === 'checkbox';

        this.checkValidity();
    }

    // For checkbox & color
    public onChange(value: any, checked: any): void {

        if (this.type === 'checkbox') {
            this.value = checked;
        } else if (this.type === 'color') {
            this.value = value;
        }
    }

    // For text and number (the click event is used to detect clicks on)
    public onKeyUp(value: any): void {

        if (this.type === 'color' || this.type === 'checkbox') {
            return;
        }

        this.checkValidity();

        if (this.isInvalid === false) {
            this.value = value;
        }
    }

    public checkValidity(): void {

        // Numeric: Check the range
        if (this.type === 'number') {

            if (this.min && this.realValue < this.min) {
                this.realValue = this.min;
            }

            if (this.max && this.realValue > this.max) {
                this.realValue = this.max;
            }

            return;
        }

        // Text: Check the format
        if(this.type === 'text') {
            switch(this.format) {

                case 'lowercase':
                    this.realValue = (this.realValue as string).toLowerCase();
                break;

                case 'uppercase':
                    this.realValue = (this.realValue as string).toUpperCase();
                break;

                case 'capitalized':
                    this.realValue = StringUtils.capitalize(this.realValue);
                break;
            }
        }

        // Email: Ensure lower case
        if(this.type === 'email') {
            this.realValue = (this.realValue as string).toLowerCase();
        }

        // Textual: Check the presence, the pattern and the size
        if (this.type === 'text' ||
            this.type === 'password' ||
            this.type === 'email') {
            this.isInvalid =    (this.required && !this.realValue) ||
                                (this.pattern && new RegExp(this.pattern).test(this.realValue)) === false ||
                                (this.min !== undefined && this.realValue.length < this.min) ||
                                (this.max !== undefined && this.realValue.length > this.max);
        }

        // Fire as a null value when invalid, that make the checking easier
        if (this.isInvalid) {
            setTimeout(() => this.valueChange.emit(null), 0);
        }
    }

}
