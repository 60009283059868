<wui-modal-layout [wuiDisabled]="isLoading">

    <div title>{{ title }}</div>

    <app-header header logo="assets/products/groundModuleDark.svg" [description]="header"></app-header> <!-- TODO ADD A COLOR OPTION, MOVE TO WUI ?-->

    <div body>

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        @switch (tabs.selected?.selector) {

            @case ('instructions') {
                <wui-section name="instructions">
                    Please follow these instructions:
                    <ul>
                        <li>Take a brand new (never registered) device.</li>
                        <li>Turn it on.</li>
                        <li>Press Continue.</li>
                    </ul>
                </wui-section>
            }

            @case ('information') {
                <wui-section name="state">
                    <wui-entry>
                        <div name>Serial</div>
                        <wui-input value [(value)]="request.serial" [required]="true" [wuiDisabled]="true"></wui-input> <!-- pattern="([0-9 A-F]{2}[:]){6}([0-9 A-F]{2})" (click)="readNfcTagUid()"></wui-input> -->
                        <div detail></div>
                    </wui-entry>
                    <wui-entry>
                        <div name>Hardware version</div>
                        <wui-input value [(value)]="request.version" [required]="true" [wuiDisabled]="true"></wui-input> <!-- pattern="([0-9 A-F]{2}[:]){6}([0-9 A-F]{2})" (click)="readNfcTagUid()"></wui-input> -->
                        <div detail></div>
                    </wui-entry>
                </wui-section>
            }

            @case ('mark') {
                <wui-section name="device registered">
                    The device has been registred into the database.<br/>
                    You can mark it now with the laser.
                </wui-section>
            }

            @case ('complete') {
                <wui-section name="complete">
                    The device is ready to use and registered.
                </wui-section>
            }
        }

    </div>

    <wui-buttons footer>

        @switch (tabs.selected?.selector) {

            @case ('information') {
                <wui-button (click)="back()">back</wui-button>
                <wui-button (click)="createDeviceAndContinue()" [wuiDisabled]="!request.serial || !request.version">continue</wui-button>
                <!-- <wui-button (click)="getLaserMarkingProject()">mark itxxx</wui-button> -->
            }

            @case ('mark') {
                <wui-button (click)="back()">back</wui-button>
                <wui-button (click)="getLaserMarkingProject()">mark it</wui-button>
                <wui-button (click)="continue()">continue</wui-button>
            }

            @case ('complete') {
                <wui-button (click)="close()">close</wui-button>
                <wui-button (click)="getLaserMarkingProject()">mark it</wui-button>
                <wui-button (click)="again()">again</wui-button>
            }

            @default {
                <wui-button (click)="back()">back</wui-button>
                <wui-button (click)="continue()">continue</wui-button>
            }
        }

    </wui-buttons>



</wui-modal-layout>
