<div class="content">
    <wui-input
        type="email"
        [(value)]="request.email"
        autocomplete="email"
        [placeholder]="'shared.email' | translate">
    </wui-input>

    <wui-input
        type="password"
        [(value)]="request.password"
        autocomplete="password"
        [placeholder]="'shared.email' | translate">
    </wui-input>

    <wui-buttons>
        <wui-button [wuiDisabled]="true">{{ 'shared.passwordLost' | translate }}</wui-button>
        <wui-button [wuiDisabled]="isInvalid" (click)="login()">{{ 'shared.login' | translate }}</wui-button>
    </wui-buttons>
</div>




