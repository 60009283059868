export enum OperationTrophiesTypes {

    PrisonersFirstOne = 'PrisonersFirstOne',
    PrisonersBiggestTotal = 'PrisonersBiggestTotal',
    PrisonersCapturedFirstOne = 'PrisonersCapturedFirstOne',
    PrisonersCapturedBiggestTotal = 'PrisonersCapturedBiggestTotal',

    DropsFirstOne = 'DropsFirstOne',
    DropsBiggestTotal = 'DropsBiggestTotal',

    LootingFirstOne = 'LootingFirstOne',
    LootingBiggestOne = 'LootingBiggestOne',
    LootingBiggestTotal = 'LootingBiggestTotal',

    DominationCapturesFirstOne = 'DominationCapturesFirstOne',
    DominationCapturesBiggestTotal = 'DominationCapturesBiggestTotal',

    AssemblyFirstOne = 'AssemblyFirstOne',
    AssemblyBiggestTotal = 'AssemblyBiggestTotal',

    ContaminationZero = 'ContaminationZero',
    ContaminationFirstOne = 'ContaminationFirstOne',
    ContaminationLastOne = 'ContaminationLastOne',
}
