import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { DevicesComponent } from './components/devices/devices.component';
import { DevicesGetComponent } from './components/devices-get/devices-get.component';
import { DeviceReadComponent } from './components/device-read/device-read.component';
import { DevicesCreateComponent } from './components/devices-create/devices-create.component';
import { DevicesNfcTagOperatorCreateComponent } from './components/devices-nfc-tag-operator-create/devices-nfc-tag-operator-create.component';
import { DevicesNfcTagGuestCreateComponent } from './components/devices-nfc-tag-guest-create/devices-nfc-tag-guest-create.component';
import { DevicesNfcTagResourceCreateComponent } from './components/devices-nfc-tag-resource-create/devices-nfc-tag-resource-create.component';
import { DevicesNfcTagPartCreateComponent } from './components/devices-nfc-tag-part-create/devices-nfc-tag-part-create.component';
import { DevicesNfcTagLocationCreateComponent } from './components/devices-nfc-tag-location/devices-nfc-tag-location-create.component';
import { DevicesNfcTagSystemCreateComponent } from './components/devices-nfc-tag-system-create/devices-nfc-tag-system-create.component';
import { DevicesHeadquarterGatewayCreateComponent } from './components/devices-headquarter-gateway-create/devices-headquarter-gateway-create.component';
import { DevicesGroundModuleCreateComponent } from './components/devices-ground-module-create/devices-ground-module-create.component';

@NgModule({
    declarations: [
        DevicesComponent,
        DevicesCreateComponent,
        DevicesGetComponent,
        DeviceReadComponent,
        DevicesNfcTagOperatorCreateComponent,
        DevicesNfcTagGuestCreateComponent,
        DevicesNfcTagResourceCreateComponent,
        DevicesNfcTagPartCreateComponent,
        DevicesNfcTagLocationCreateComponent,
        DevicesNfcTagSystemCreateComponent,
        DevicesHeadquarterGatewayCreateComponent,
        DevicesGroundModuleCreateComponent,
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
})
export class DevicesModule {}
