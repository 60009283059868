import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { faMagnifyingGlass, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalConfirmComponent } from '@lightning/backoffice/app/shared/components/modal-confirm/modal-confirm.component';
import { ModalTableFilterComponent } from '@lightning/backoffice/app/shared/components/modal-table-filter/modal-table-filter.component';
import { FiltersOptions } from '@lightning/backoffice/app/shared/models/table-filters-options.types';
import { TableLine } from '@lightning/backoffice/app/shared/models/table-line.types';
import { StoreOrder, StoreOrderStatus, StoreOrdersSearchRequest, StoreOrdersSearchResponse, Account } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { KeyValuePipe, OverlayService } from '@lightning/wild-ui';

import { AccountsEditComponent } from '../../../accounts/components/accounts-edit/accounts-edit.component';
import { StoreOrdersEditComponent } from '../store-orders-edit/store-orders-edit.component';

@Component({
    selector: 'app-store-orders',
    templateUrl: './store-orders.component.html',
    styleUrls: ['./store-orders.component.scss']
})
export class StoreOrdersComponent implements OnInit {

    public icons = {
        search: faMagnifyingGlass,
        delete: faXmarkCircle
    };

    public lines: Array<TableLine<StoreOrder>> = [];

    public filters: { [filter: string]: string } = {};

    private isLoading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private keyValuePipe: KeyValuePipe) { }

    ngOnInit(): void {

        this.filters = structuredClone(this.route.snapshot.queryParams);

        this.load(false);
    }

    public load(next: boolean): void {

        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        const request: StoreOrdersSearchRequest = {
            index: next ? this.lines.length : 0,
            limit: 50,
            ...this.filters
        }

        this.onlineService
            .searchStoreOrders(request)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: StoreOrdersSearchResponse) => {

                    const lines: Array<TableLine<StoreOrder>> = response.items.map((item: StoreOrder) => {
                        return {
                            isSelected: false,
                            data: item
                        }
                    });

                    this.lines = next ? this.lines.concat(lines) : lines;
                }
            });
    }

    public async searchBy(filter: string) {

        const options: FiltersOptions = {
            description: 'search by ' + filter,
            type: 'text',
            choices: [],
            value: this.filters[filter]
        };

        switch(filter) {

            case 'email':
                options.type = 'email';
            break;

            case 'status':
                options.choices = this.keyValuePipe.transform(StoreOrderStatus);
            break;
        }

        // Selection modal
        const value: string | undefined = await this.overlayService.openModal({
            component: ModalTableFilterComponent,
            inputs: {
                options
            }
        });

        // Canceled
        if(value === undefined) {
            return;
        }

        if(value === '') {
            delete this.filters[filter];
        } else {
            this.filters[filter] = value;
        }

        this.load(false);

        // Update the url according the filter
        this.router.navigate([], { queryParams: this.filters });
    }

    public async edit(order: StoreOrder): Promise<void> {

        const updatedOrder = await this.overlayService.openModal({
            component: StoreOrdersEditComponent,
            inputs: {
                title: `Order ${order.id}`,
                order
            }
        });

        if (updatedOrder) {
            this.load(false);
        }
    }

    public editAccount(id: string | undefined): void {

        if (id === undefined) {
            return;
        }

        this.onlineService.getAccount(id).subscribe({
            next: (account: Account) => {
                this.overlayService.openModal({
                    component: AccountsEditComponent,
                    inputs: {
                        title: `Account ${account.id}`,
                        account
                    }
                });
            }
        });
    }

    public async delete(id: string) {

        if (await this.overlayService.openModal({
            component: ModalConfirmComponent,
            inputs: {
                description: `Are you sure you want to remove the order ${id}?`
            }})) {

            this.onlineService.deleteStoreOrder(id).subscribe({
                next: () => {
                    this.lines = this.lines.filter(line => line.data.id !== id);
                }
            })
        }

    }







    // ------------------------------------------------------------------
    // TODO: MOVE IT INTO AN UTILS FILE


    public selectAll(event: any): void {

        for (const line of this.lines) {
            line.isSelected = event.target.checked;
        }
    }

    public select(line: TableLine<StoreOrder>, event: MouseEvent): void {

        line.isSelected = !line.isSelected;

        if(event.shiftKey || event.ctrlKey) {

            const indexClicked = this.lines.indexOf(line);

            if (indexClicked > 0) {

                let indexTop: number | undefined;

                for(let i = indexClicked - 1; i >= 0; i--) {
                    if(this.lines[i].isSelected === line.isSelected) {
                        indexTop = i;
                        break;
                    }
                }

                if (indexTop !== undefined) {

                    for(let i = indexClicked; i > indexTop; i--) {
                        this.lines[i].isSelected = line.isSelected;
                    }

                    return;
                }
            }

            if (indexClicked < this.lines.length) {

                let indexBottom: number | undefined;

                for(let i = indexClicked + 1; i <= this.lines.length; i++) {
                    if(this.lines[i].isSelected === line.isSelected) {
                        indexBottom = i;
                        break;
                    }
                }

                if (indexBottom !== undefined) {

                    for(let i = indexClicked; i < indexBottom; i++) {
                        this.lines[i].isSelected = line.isSelected;
                    }

                    return;
                }

            }

        }
    }


}
