

<div class="icon">
    🔥
</div>

<div class="message" #message (click)="open()" [style.cursor]="href || routerLink ? 'pointer' : 'initial'">
    <ng-content></ng-content>
</div>

<div class="close" (click)="close()">
    ⨉
</div>

