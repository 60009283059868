import { Transform, IsBoolean, IsOptional, IsString, IsNotEmpty, ApiProperty, IsNumber } from '@lightning/decorators';
import { OperatorProfile, OperatorNetworks } from '../entities/operator.interface';
import { PaginedRequest, PaginedResponse } from './pagined.dto';
import { Expose } from 'class-transformer';

export class OperatorsSearchRequest extends PaginedRequest {

    @ApiProperty()
    @Expose()
    @IsOptional()
    @IsString()
    public id?: string;

    @ApiProperty()
    @Expose()
    @Transform(({ value }: { value: string }) => parseInt(value) || undefined)
    @IsOptional()
    @IsNumber()
    public number?: number;

    @ApiProperty()
    @Expose()
    @IsOptional()
    @IsString()
    public name?: string;

    @ApiProperty()
    @Expose()
    @IsOptional()
    @IsString() // TODO: Enum
    public status?: string;

    @ApiProperty()
    @Expose()
    @IsOptional()
    @IsBoolean()
    @Transform(({ value }: { value: string }) => value === 'true')
    public isLegend?: boolean;
}

export class OperatorsSearchResponse extends PaginedResponse {

    public items: Array<OperatorProfile> = [];
}

export class OperatorsCreateRequest {

    @IsString()
    @IsNotEmpty()
    public accountId = '';

    @IsString()
    @IsNotEmpty()
    public name = '';

    @IsBoolean()
    @Transform(({ value }: { value: string }) => value === 'true')
    public isLegend = false;
}

export class OperatorsUpdateRequest {

    // public name?: string; Not updatable yet

    public hashTags = new Array<string>();

    public networks = new OperatorNetworks();
}


export class OperatorsAvatarOptions {

    @IsBoolean()
    @IsOptional()
    @Transform(({ value }: { value: string }) => value === 'true')
    public ignoreStatus?: boolean;
}
