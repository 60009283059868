<wui-modal-layout>

    <div title>{{ title }}</div>

    <div body>

        <wui-section name="description">
            <wui-entry>
                <div name>Id</div>
                <div value>
                    {{ operator.id }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Number</div>
                <div value>
                    {{ operator.number }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Name</div>
                <div value>
                    {{ operator.name }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Status</div>
                <div value
                    [class.error]="operator.status === OperatorStatus.Classified">
                    {{ operator.status | capitalize }}
                </div>
            </wui-entry>
            <wui-entry>
                <div name>Is legend</div>
                <div value>
                    {{ operator.isLegend ? 'Yes' : 'No' }}
                </div>
            </wui-entry>

        </wui-section>

        <wui-entry>
            <div name>Neutral</div>
            <wui-input-image value [imageUrl]="images.neutral"></wui-input-image>
        </wui-entry>
        <wui-entry>
            <div name>Winning</div>
            <wui-input-image value [imageUrl]="images.winning"></wui-input-image>
        </wui-entry>
        <wui-entry>
            <div name>Losing</div>
            <wui-input-image value [imageUrl]="images.losing"></wui-input-image>
        </wui-entry>

        <wui-section name="nfc tags">
            <div *ngFor="let tag of operator.nfcTags">
                <wui-entry [wuiDisabled]="isExpirated(tag.expiration)">
                    <div name>{{ tag.serial }}</div>
                    <div value [class.error]="isExpirated(tag.expiration)">{{ tag.expiration | date: 'yyyy-MM-dd' }}</div>
                </wui-entry>
            </div>
        </wui-section>

        <wui-section name="raw">
            <wui-json [value]="operator"></wui-json>
        </wui-section>

    </div>

    <wui-buttons footer>
        <wui-button (click)="cancel()">cancel</wui-button>
        <!-- <wui-button (click)="confirm()" [wuiDisabled]="isSending">confirm</wui-button> -->
    </wui-buttons>

</wui-modal-layout>
