import { AfterViewInit, Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

const CLOSED_TIPS_STORAGE_NAME = 'closed-tips';

@Component({
  selector: 'wui-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss']
})
export class TipComponent implements AfterViewInit {

    @Input()
    public href = '';

    @Input()
    public target = '';

    @Input()
    public routerLink = '';

    @ViewChild('message', { read: ViewContainerRef })
    private messageReference: ViewContainerRef | undefined;

    private messageHash = 0;

    constructor(private router: Router, private hostReference: ViewContainerRef) {}

    public ngAfterViewInit(): void {

        const message = this.messageReference?.element.nativeElement.innerHTML;

        // Calculate the hash o the current message
        for (let i = 0, len = message.length; i < len; i++) {
            this.messageHash = (this.messageHash << 5) - this.messageHash + message.charCodeAt(i);
            this.messageHash |= 0;
        }

        // Load the list of the closed tips
        const stored = localStorage.getItem(CLOSED_TIPS_STORAGE_NAME) || '{ "tips": [] }';

        const data = JSON.parse(stored);

        // Hide this tip if its hash is present
        if (data.tips.includes(this.messageHash)) {
            this.hostReference.element.nativeElement.style.display = 'none';
        }
    }

    public open(): void {

        if (this.href) {
            window.open(this.href, this.target);
        }

        if (this.routerLink) {
            this.router.navigateByUrl(this.routerLink);
        }
    }

    public close(): void {

        // Load the list of the closed tips
        const stored = localStorage.getItem(CLOSED_TIPS_STORAGE_NAME) || '{ "tips": []}';

        const data = JSON.parse(stored);

        // Add the hash of the current tip
        data.tips.push(this.messageHash);

        // Save
        localStorage.setItem(CLOSED_TIPS_STORAGE_NAME, JSON.stringify(data));

        // Hide the tip
        this.hostReference.element.nativeElement.style.display = 'none';
    }

}
