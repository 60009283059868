import { Component, Input, OnInit } from '@angular/core';
import { Account, Feedback } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService } from '@lightning/wild-ui';
import { AccountsEditComponent } from '../../../accounts/components/accounts-edit/accounts-edit.component';

@Component({
    selector: 'app-feedbacks-edit',
    templateUrl: './feedbacks-edit.component.html',
    styleUrls: ['./feedbacks-edit.component.scss'],
})
export class FeedbacksEditComponent implements OnInit {

    // public FeedbackStatus = FeedbackStatus;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public resolve = (value: Feedback | null = null) => Function;

    @Input()
    public title = '';

    @Input()
    public feedback = new Feedback();

    public constructor(
        private overlayService: OverlayService,
        private onlineService: OnlineService) {}

    public ngOnInit(): void {

        // Update the feedback if there is more data to load
        if (this.feedback.hasScreenshot) {
            this.onlineService.getFeedback(this.feedback.id).subscribe({
                next: feedback => this.feedback = feedback
            });
        }
    }

    public editAccount(id: string | undefined): void {

        if (id === undefined) {
            return;
        }

        this.onlineService.getAccount(id).subscribe({
            next: (account: Account) => {
                this.overlayService.openModal({
                    component: AccountsEditComponent,
                    inputs: {
                        title: `Account ${account.id}`,
                        account
                    }
                });
            }
        });
    }

    public cancel(): void {

        this.resolve(null);

        this.overlayService.closeModal();
    }
}
