
// CAUTION THIS FILE IS STILL NAMED operator.INTERFACE.ts
// because rename it to operator.entity.ts makes it readable
// by the nest/swagger pluggin and there is an issue with the
// trophies property (why? I don't know for the moment ^^)


import { OperationTrophiesTypes } from '../enums/operation-trophies.enum';
import { OperatorRoles, OperatorStatus } from '../enums/operators.enum';
import { NfcTag } from './nfc-tag.entity';
import { OperationReport } from './operation-report.entity';
import { Score } from './score.entity';

export class OperatorSelector {

    public number = 0;

    public name?: string = '';
}

export class OperatorUnderOperation extends OperatorSelector {

    public isGuest = false;

    public teamId?: string;

    public role?: OperatorRoles;

    public score: Score = {
        points: 0,
        rank: 0
    };

    public data: OperatorUnderOperationData = {};
}

export class OperatorUnderOperationData {

    public isDead?: boolean;

    public stats?: {

        isZero?: boolean,

        isFirst?: boolean,

        isLast?: boolean,

        count?: number,

        biggest?: number,

        total?: number,

        isFirstCaptured?: boolean,

        totalCaptured?: number,
    }

}

export class OperatorProfile extends OperatorSelector {

    public id?: any;

    public status: OperatorStatus = OperatorStatus.Idle;

    public expiration = new Date();

    public operations: Array<OperationReport> = [];

    public trophies: Array<{ type: OperationTrophiesTypes, count: number }> = [];

    public hashTags: Array<string> = [];

    public nfcTags: Array<NfcTag> = [];

    public isLegend = false;

    public networks = new OperatorNetworks();
}

export class OperatorAvatars {

    public id?: any;

    public operatorId!: string;

    public neutral?: Buffer;

    public wining?: Buffer;

    public losing?: Buffer;
}

export class OperatorNetworks {

    public youtube = '';

    public instagram = '';

    public tiktok = '';
}

// // TODO: Consider this (also appliable to device ):
// export interface OperatorRefactorized {
//     id: number;
//     name: string;
//     isGuest: boolean;
//     teamId: string;

//     data?: {                // general purpose data object (generic)
//         operation?: {       // operation data object initialized and cleared by operation.base, declared out of here (new interface OperationOperatorData.interface.ts )
//             role?: string;
//             score?: {
//                 points: number;
//                 rank: number;
//             },
//             process?: any;  // stats for trohies, special states...
//         },

//         // other data out of operations
//     }
// }
