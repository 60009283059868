<div class="layout vertical scrollable" (wuiScrolledBottom)="load(true)" [wuiScrolledBottomThreshold]="100">

    <table>

        <tr class="header">

            <td>n. </td>

            <td>
                id
                <fa-icon
                    [class.active]="filters['id'] !== undefined"
                    (click)="searchBy('id')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                created
                <fa-icon
                    [class.active]="filters['created'] !== undefined"
                    (click)="searchBy('created')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                product
                <fa-icon
                    [class.active]="filters['product'] !== undefined"
                    (click)="searchBy('product')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                serial
                <fa-icon
                    [class.active]="filters['serial'] !== undefined"
                    (click)="searchBy('serial')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                hardware version
                <fa-icon
                    [class.active]="filters['version'] !== undefined"
                    (click)="searchBy('version')"
                    [icon]="icons.search"></fa-icon>
            </td>

            <td>
                details
            </td>

            <td>

            </td>

        </tr>

        @for(line of lines; track line; let index = $index) {

            <tr> <!---(click)="edit(line.data)">-->

                <td>{{ index + 1 }}</td>

                <td>{{ line.data.id }}</td>

                <td [style.color]="line.calculated.createdColor">
                    {{ line.data.created | date: 'YYYY-MM-dd HH:mm' }}
                </td>

                <td [style.color]="line.calculated.productColor">
                    {{ line.data.product }}
                </td>

                <td>{{ line.data.serial }}</td>

                <td>{{ line.data.version }}</td>

                <td [title]="line.data.details | json">{{ line.data.details | json }}</td>

                <td wuiStopPropagation>

                    <span title="laser mark" (click)="mark(line.data)">🔥</span>

                    <!-- <fa-icon (click)="view(line.data.id)" [icon]="icons.view"></fa-icon> -->
                    <fa-icon title="delete" (click)="delete(line.data.id)" [icon]="icons.delete"></fa-icon>
                </td>

            </tr>
        }

    </table>

</div>
