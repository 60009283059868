export enum OperatorStatus {
    Idle = 'iddle',
    Active = 'active',
    Classified = 'classified',
}

export enum OperatorAvatarName {
    Neutral = 'neutral',
    Winning = 'winning',
    Losing = 'losing'
}

export enum OperatorRoles {
    Survivor = 'survivor',
    Deserter = 'deserter',
    Contamined = 'contamined',
}
