<div class="header" wuiTacticalCorners [wuiTacticalCornersOptions]="{ disappear: false }">

    <div class="title">
        <div>LIGHTNING TACTICAL BACKOFFICE</div>
        <div class="host">
            {{ host ? host.name : '' | uppercase }}

            @if(cicd.tag || cicd.pipeline) {
                : #{{ cicd.tag || cicd.pipeline }}
            }
        </div>
    </div>

    <div class="logout" (click)="logout()">
        {{ onlineService.tokenPayload?.name }}
    </div>

    <div class="screen" (click)="toggleFullscreen()" title="enter / leave fullscreen"> ⛶ </div>

</div>

<div class="body">

    <div class="menu" wuiTacticalCorners [wuiTacticalCornersOptions]="{ disappear: false }">
        <div class="button" routerLinkActive="active" [routerLink]="'dashboard'">DASHBOARD</div>
        <div class="separator"></div>
        <div class="button" routerLinkActive="active" [routerLink]="'accounts'">ACCOUNTS</div>
        <div class="button" routerLinkActive="active" [routerLink]="'operators'">OPERATORS</div>
        <div class="separator"></div>
        <div class="button" routerLinkActive="active" [routerLink]="'operations/reports'">OPERATIONS</div>
        <div class="separator"></div>
        <div class="button" routerLinkActive="active" [routerLink]="'feedbacks'">FEEDBACKS</div>
        <div class="separator"></div>
        <div class="button" routerLinkActive="active" [routerLink]="'store/orders'">ORDERS</div>
        <div class="button">
            <span routerLinkActive="active" [routerLink]="'devices'">DEVICES </span>
            <span routerLinkActive="active" [routerLink]="'devices/create'">/ CREATE</span>
        </div>

        <div class="button" routerLinkActive="active" [routerLink]="'firmwares'">FIRMWARES</div>
        <div class="separator"></div>
        <div class="button" (click)="openWebsite()">WEBSITE</div>
        <div class="button" (click)="openHeadquarter()">HEADQUARTER</div>
        <div class="button" (click)="openSentry()">SENTRY</div>
        <!-- open mongo atlas https://cloud.mongodb.com/v2/60784dd974807261c9928e56#/overview -->

        <div class="separator"></div>


        <div class="button"
            (click)="workBoardService.isConnected ? workBoardService.disconnect() : workBoardService.connect()">
            {{ workBoardService.isConnected ? 'DISCONNECT' : 'CONNECT'}}
            WORK BOARD
        </div>

        <div class="separator"></div>

        <div class="button"
            (click)="readDevice()">
            READ NFC DEVICE
        </div>

        <div class="button"
            (click)="getDevice()">
            GET NFC DEVICE INFORMATION
        </div>

    </div>

    <div class="content" wuiTacticalCorners [wuiTacticalCornersOptions]="{ disappear: false }">
        <router-outlet></router-outlet>
    </div>

</div>

