/* eslint-disable @typescript-eslint/member-ordering */
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { uniqueNamesGenerator, adjectives, colors, animals } from 'unique-names-generator';
import { ProductsWithSoftware, Firmware, FirmwareCreateFormDataRequest, FirmwareUpdateFormDataRequest, SementicVersionUtils, SoftwaresChannels } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService } from '@lightning/wild-ui';

@Component({
    selector: 'app-firmwares-edit',
    templateUrl: './firmwares-edit.component.html',
    styleUrls: ['./firmwares-edit.component.scss'],
})
export class FirmwaresEditComponent implements OnInit {

    @Input()
    public resolve = (value: Firmware | null = null) => Function;

    @Input()
    public title = '';

    @Input()
    public firmware: Firmware = {
        id: undefined,
        name: uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals ]}),
        description: '-',
        version: {
            major: 0,
            minor: 0,
            patch: 0
        },
        channel: SoftwaresChannels.Beta,
        target: {
            product: ProductsWithSoftware.GroundModule,
            hardwareVersion: {
                major: 0,
                minor: 0,
                patch: 0
            }
        }
    };

    public Product = ProductsWithSoftware;

    public SoftwaresChannels = SoftwaresChannels;

    public request: FirmwareCreateFormDataRequest = new FirmwareCreateFormDataRequest();

    public progress = 0;

    public isSending = false;

    constructor(private overlayService: OverlayService, private onlineService: OnlineService) {}

    public ngOnInit(): void {

        this.request = {
            name: this.firmware.name,
            version: SementicVersionUtils.toString(this.firmware.version),
            description: this.firmware.description,
            channel: this.firmware.channel,

            // This is a form data request, nested objects not supported
            targetProduct: this.firmware.target.product,
            targetHardwareVersion: SementicVersionUtils.toString(this.firmware.target.hardwareVersion, { ignorePatch: true }),

            file: undefined
        };
    }

    public selectFile(event: any): void {

        if(event.target.files.length === 0) {

            this.request.file = undefined;

            return;
        }

        this.request.file = event.target.files[0];

        const fileName = (this.request.file.name as string)
            .replace('.bin', '');

        const fileNameFields = fileName
            .split(' ');

        if (fileNameFields[0] === 'firmware' && fileNameFields.length === 4) {

            this.request.name = fileName;
            this.request.targetProduct = fileNameFields[1] as ProductsWithSoftware;
            this.request.targetHardwareVersion = fileNameFields[2];
            this.request.version = fileNameFields[3];
        }
    }

    public downloadFile(): void {

        if (!this.firmware.id) {
            return;
        }

        window.open(`${this.onlineService.backendUrl}/firmwares/${this.firmware.id}/file`, `_blank`);
    }

    public cancel(): void {

        this.resolve(null);

        this.overlayService.closeModal();
    }

    public confirm(): void {

        this.firmware.id ? this.update() : this.create();
    }

    private create() : void {

        this.isSending = true;

        this.onlineService.createFirmware(this.request).subscribe({
            next: (event) => {

                console.log(event);

                if (event.type === HttpEventType.UploadProgress && event.total) {

                    this.progress = Math.round(100 * event.loaded / event.total);

                    return;
                }

                if (event instanceof HttpResponse) {

                    this.isSending = false;

                    this.resolve(event.body);

                    this.overlayService.closeModal();

                    return;
                }
            },
            error: () => {

                this.isSending = false;
            }
        });
    }

    private update() : void {

        const updateRequest: FirmwareUpdateFormDataRequest = {
            id: this.firmware.id,
            ...this.request
        }

        this.isSending = true;

        this.onlineService.updateFirmware(updateRequest).subscribe({
            next: (event) => {

                console.log(event);

                if (event.type === HttpEventType.UploadProgress && event.total) {

                    this.progress = Math.round(100 * event.loaded / event.total);

                    console.log('Progress', this.progress);

                    return;
                }

                if (event instanceof HttpResponse) {

                    this.isSending = false;

                    this.resolve(event.body);

                    this.overlayService.closeModal();

                    return;
                }
            },
            error: () => {

                this.isSending = false;
            }
        });

    }
}
