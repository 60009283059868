<wui-modal-layout>

    <div title>{{ title }}</div>

    <div body>

        <wui-section name="description">
            <wui-entry>
                <div name>Name</div>
                <wui-input value
                    [(value)]="request.name">
                </wui-input>
            </wui-entry>
            <wui-entry>
                <div name>Description</div>
                <wui-input value
                    [(value)]="request.description">
                </wui-input>
            </wui-entry>
            <wui-entry>
                <div name>Version</div>
                <wui-input value
                    [(value)]="request.version">
                </wui-input>
            </wui-entry>
            <wui-entry>
                <div name>Channel</div>
                <wui-select value [(value)]="request.channel">
                    @for(channel of SoftwaresChannels | keyValue; track channel) {
                        <option [value]="channel.value">{{ channel.key }}</option>
                    }
                </wui-select>
            </wui-entry>
        </wui-section>

        <wui-section name="target">
            <wui-entry>
                <div name>Target product</div>
                <wui-select value [(value)]="request.targetProduct">
                    @for(product of Product | keyValue; track product) {
                        <option [value]="product.value">{{ product.key }}</option>
                    }
                </wui-select>
            </wui-entry>
            <wui-entry>
                <div name>Target hardware version</div>
                <wui-input value
                    [(value)]="request.targetHardwareVersion">
                </wui-input>
                <div detail>
                    Note: The patch number doesn't matter because all targets of the same major.minor.xxx versions use the same firmware.
                </div>
            </wui-entry>
        </wui-section>

        <wui-section name="file">
            <wui-entry>
                <div name>File</div>
                <wui-input value
                    type="file" (change)="selectFile($event)">
                </wui-input>
                <div detail>
                    Pro tip: To use the auto filling feature, name your files using the following notation: 'firmware [target-product] [target-hardware-version] [software-version].bin'.</div>
            </wui-entry>

            @if(firmware.id) {
                <wui-entry>
                    <div name></div>
                    <wui-button value
                        (click)="downloadFile()">
                        download
                    </wui-button>
                </wui-entry>
            }

        </wui-section>

    </div>

    <wui-buttons footer>
        <wui-button (click)="cancel()">cancel</wui-button>
        <wui-button (click)="confirm()" [wuiDisabled]="isSending">confirm</wui-button>
    </wui-buttons>

</wui-modal-layout>
