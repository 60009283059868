<wui-modal-layout [wuiDisabled]="isLoading">

    <div title>{{ title }}</div>

    <app-header header logo="assets/products/nfcTagPartDark.svg" [description]="header"></app-header> <!-- TODO ADD A COLOR OPTION, MOVE TO WUI ?-->

    <div body>

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        @switch (tabs.selected?.selector) {

            @case ('instructions') {
                <wui-section name="instructions">
                    Please follow these instructions:
                    <ul>
                        <li>Take a brand new (never registered) part tag.</li>
                        <li>Put it on the work board.</li>
                        <li>Press Continue.</li>
                    </ul>
                </wui-section>
            }

            @case ('serial') {
                <wui-section name="tag">
                    <wui-entry>
                        <div name>Serial</div>
                        <wui-input value [(value)]="request.serial" [required]="true" pattern="([0-9 A-F]{2}[:]){6}([0-9 A-F]{2})" (click)="readNfcTagUid()"></wui-input>
                        <div detail>Press 'read serial' to autofill this field.</div>
                    </wui-entry>
                    <wui-entry>
                        <div name>Hardware version</div>
                        <wui-input value [(value)]="request.version" [required]="true"></wui-input>
                    </wui-entry>
                </wui-section>
            }

            @case ('part') {
                <wui-section name="part">
                    <wui-entry>
                        <div name>Number</div>
                        <wui-input value [(value)]="request.number" [required]="true" type="number" [min]="1"></wui-input>
                    </wui-entry>
                </wui-section>
            }

            @case ('write') {
                <wui-section name="write">
                    <wui-entry *ngIf="nfcTag">
                        <div name>Records</div>
                        <div value>
                            <div *ngFor="let record of nfcTag.records" class="record">{{ record }}</div>
                        </div>
                    </wui-entry>
                </wui-section>
            }

            @case ('complete') {
                <wui-section name="complete">
                    The tag is ready to use and registered as device id {{ nfcTag?.id }}.
                </wui-section>
            }
        }

    </div>

    <wui-buttons footer>

        @switch (tabs.selected?.selector) {

            @case ('instructions') {
                <wui-button (click)="close()">cancel</wui-button>
                <wui-button (click)="readNfcTagUid(); continue()">continue</wui-button>
            }

            @case ('serial') {
                <wui-button (click)="back()">back</wui-button>
                <wui-button (click)="continue()" [wuiDisabled]="!request.serial || !request.version">continue</wui-button>
            }

            @case ('part') {
                <wui-button (click)="back()">back</wui-button>
                <wui-button (click)="createNfcTagAndContinue()" [wuiDisabled]="!request.number">continue</wui-button>
                <wui-button (click)="getLaserMarkingProject()">mark itxxx</wui-button>
            }

            @case ('write') {
                <wui-button [wuiDisabled]="true">back</wui-button>
                <wui-button (click)="writeNfcTagAndContinue()">write</wui-button>
            }

            @case ('complete') {
                <wui-button (click)="close()">close</wui-button>
                <wui-button (click)="getLaserMarkingProject()">mark it</wui-button>
                <wui-button (click)="next()">next one ({{ this.request.number + 1 }})</wui-button>
            }
        }
    </wui-buttons>

</wui-modal-layout>
