<div class="layout vertical scrollable" (wuiScrolledBottom)="load(true)" [wuiScrolledBottomThreshold]="100">

    <table>

        <tr class="header">

            <td>n. </td>

            <td>
                id
                <fa-icon
                    [class.active]="filters['id'] !== undefined"
                    (click)="searchBy('id')"
                    [icon]="icons.search">
                </fa-icon>
            </td>

            <td>
                created
                <fa-icon
                    [class.active]="filters['created'] !== undefined"
                    (click)="searchBy('created')"
                    [icon]="icons.search">
                </fa-icon>
            </td>

            <td>
                author
                <fa-icon
                    [class.active]="filters['authorAccountId'] !== undefined"
                    (click)="searchBy('authorAccountId')"
                    [icon]="icons.search">
                </fa-icon>
            </td>

            <td>
                description
            </td>

            <td>
                has screenshot
            </td>

        </tr>

        <tr *ngFor="let line of lines; let i = index" (click)="edit(line.data)">

            <td>{{ i + 1 }}</td>

            <td>{{ line.data.id }}</td>

            <td>{{ line.data.created | date }}</td>

            <td (click)="editAccount(line.data.authorAccountId)" wuiStopPropagation>{{ line.data.authorAccountId }}</td>

            <td>{{ line.data.description }}</td>

            <td>{{ line.data.hasScreenshot ? 'yes' : 'no' }}</td>

            <td wuiStopPropagation>
                <fa-icon (click)="delete(line.data.id)" [icon]="icons.delete"></fa-icon>
            </td>

        </tr>

    </table>

</div>
